<template>
    <div class="champions">
        <h1 class="text-center">Individual Champions</h1>
        <b-carousel
            v-model="slide"
            controls
            indicators
            background="#ababab"
            style="text-shadow: 2px 2px 2px #000;"
        >
            <b-carousel-slide
                img-src="/imgs/champs/2023.png"
                caption="2023"
                text="Nate Lesney defeated Gary Buchsen"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2022.jpg"
                caption="2022"
                text="Drew Hurt defeated Ken Williams"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2021.jpg"
                caption="2021"
                text="Clay Talarek defeated Chris Arnold"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2020.png"
                caption="2020"
                text="Chris Arnold defeated Griffin Hurt"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2019.jpeg"
                caption="2019"
                text="Matt Von Frisch defeated Nate Lesney"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2018.jpeg"
                caption="2018"
                text="Drew Hurt defeated John Arnold"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2017.jpg"
                caption="2017"
                text="Greg Dale defeated Clay Talarek"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2016.jpeg"
                caption="2016"
                text="Clay Talarek defeated Ken Williams"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2015.jpg"
                caption="2015"
                text="John Arnold defeated Drew Hurt"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2014.png"
                caption="2014"
                text="Jeff Fitzgerald defeated Greg Dale"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2013.jpeg"
                caption="2013"
                text="Kevin Malloy defeated Clay Talarek"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2012.jpeg"
                caption="2012"
                text="Josh Sefchick defeated Joel Hurt"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2011.jpeg"
                caption="2011"
                text="Ed Sabol defeated Josh Sefchick"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2010.jpg"
                caption="2010"
                text="Chris Jones defeated Drew Hurt"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2009.jpeg"
                caption="2009"
                text="Chris Jones defeated Kevin Malloy"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2008.jpg"
                caption="2008"
                text="Ed Sabol defeated Ken Williams"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2010.jpg"
                caption="2007"
                text="Chris Jones defeated Joel Hurt"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2006.jpg"
                caption="2006"
                text="Ed Sabol defeated Kevin Malloy"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2005.jpeg"
                caption="2005"
                text="Gary Buchsen defeated Chris Straub"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2004.jpeg"
                caption="2004"
                text="Jason Short defeated Roger Hurt"
            />
            <b-carousel-slide
                img-src="/imgs/champs/2003.jpeg"
                caption="2003"
                text="Chris Arnold defeated Roger Hurt"
            />
        </b-carousel>
        <div>
            <br>
            <p>2003 - Chris Arnold defeated Roger Hurt</p><br>
            <p>2004 - Jason Short defeated Roger Hurt</p><br>
            <p>2005 - Gary Buchsen defeated Chris Straub</p><br>
            <p>2006 - Ed Sabol defeated Kevin Malloy</p><br>
            <p>2007 - Chris Jones defeated Joel Hurt</p><br>
            <p>2008 - Ed Sabol defeated Ken Williams</p><br>
            <p>2009 - Chris Jones defeated Kevin Malloy</p><br>
            <p>2010 - Chris Jones defeated Drew Hurt</p><br>
            <p>2011 - Ed Sabol defeated Josh Sefchick</p><br>
            <p>2012 - Josh Sefchick defeated Joel Hurt</p><br>
            <p>2013 - Kevin Malloy defeated Clay Talarek</p><br>
            <p>2014 - Jeff Fitzgerald defeated Greg Dale</p><br>
            <p>2015 - John Arnold defeated Drew Hurt</p><br>
            <p>2016 - Clay Talarek defeated Ken Williams</p><br>
            <p>2017 - Greg Dale defeated Clay Talarek</p><br>
            <p>2018 - Drew Hurt defeated John Arnold</p><br>
            <p>2019 - Matt Von Frisch defeated Nate Lesney</p><br>
            <p>2020 - Chris Arnold defeated Griffin Hurt</p><br>
            <p>2021 - Clay Talarek defeated Chris Arnold</p><br>
            <p>2022 - Drew Hurt defeated Ken Williams</p><br>
            <p>2023 - Nate Lesney defeated Gary Buchsen</p><br>
        </div>
    </div>
</template>

<script>
export default {
    data: () => { return {
        slide: 0,
        sliding: null
    }}
}
</script>

<style lang="scss">
.champions {
    &__list {
        display: flex;
    }
    &__left {
        flex: 2;
    }
    &__right {
        flex: 1;
    }
    &__trophy {
        width: 100%;
    }
}
</style>