<template>
    <div>
        <h1 class="text-center">Drinks</h1>
        <br>
        <div class="drink_grp">
            <h3>The Gray Jacket Cocktail</h3>
            <div>
                <div class="gj_img_cont">
                    <img class="gj_img" src="@/assets/img/gjcocktail.png" />
                </div>
                <div>
                    <p class="txt">The Gray Jacket Cocktail made its debut in 2010. The refreshing blend of Fresca™ and Beefeater™ Gin with a touch of lime was an immediate hit. Its mystical powers have led the participants who drink the most GJs on Friday to great success including assisting Big Ed Sabol to his record-tying third Gray Jacket title in 2011. Who will take advantage of this magical elixir to propel them this year?</p>
                    <br>
                    <p class="txt">Recipe: One Part Beefeater™ Gin, One Part Fresca™, Squeeze of Fresh Lime, Served Over Ice</p>
                </div>
            </div>
        </div>
        <div class="drink_grp">
            <h3>Sarah Clayton</h3>
            <div class="gj_img_cont">
                <img class="gj_img" src="@/assets/img/sccocktail.jpeg"/>
            </div>
            <div>
                <p>The Sarah Clayton made its debut in 2014.</p>
                <br>
                <p>Recipe: One Part Jim Beam™ Honey, One Part Ginger Ale, Served Over Ice</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">

.gj_img {
    float: left;
    margin-right: 15px;
    // width: 50%;
    height: 200px;
}

.drink_grp {
    overflow: auto;
}

.txt {
    text-align: justify;
}

</style>