<template>
    <div class="scramble">
        <h1 class="text-center">Scramble Champions</h1>
        <div>
            <p>2023 - Chris Arnold, John Arnold, Gary Buchsen, Nate Lesney</p><br>
            <p>2022 - Drew Hurt, Joel Hurt, Nate Lesney, and Ed Sabol</p><br>
            <p>2021 - Nate Lesney, Chris Arnold, Ken Williams, Ed Sabol</p><br>
            <p>2020 - Nate Lesney, Clay Talarek, Matt Von Frisch, Ed Sabol</p><br>
            <p>2019 - Chris Arnold, John Arnold, Nate Lesney, and Matt Von Frisch</p><br>
            <p>2018 - Ted Boscia, Greg Dale, John Arnold, and Jeff Fitzgerald</p><br>
            <p>2017 - Josh Sefchick, Chris Arnold, Ed Sabol, Randal Sydeski</p><br>
            <p>2016 - Josh Sefchick, Chris Arnold, Ted Boscia, Brian Wilson</p><br>
            <p>2015 - Gary Buchsen, Ted Boscia, Ken Williams, Jason Cox</p><br>
            <p>2014 - John Arnold, Ted Boscia, Josh Sefchick, Ken Williams</p><br>
            <p>2013 - Gary Buchsen, Ed Sabol, Clay Talarek, Matt Von Frisch - GJ record 62 (unaudited and wind-aided)</p><br>
            <p>2012 - Ted Boscia, Kevin Malloy, Ed Sabol, Ken Williams</p><br>
            <p>2011 - Drew Hurt, Ed Sabol, Josh Sefchick, Matt Von Frisch</p><br>
            <p>2010 - Ed Sabol, Josh Sefchick, Clay Talarek, Matt Von Frisch</p><br>
            <p>2009 - John Arnold, Drew Hurt, Clay Talarek, Brian Wilson</p><br>
            <p>2008 - Chris Arnold, John Arnold, Joel Hurt, Josh Sefchick</p><br>
            <p>2007 - Gary Buchsen, Jeff Fitzgerald, Roger Hurt, Chris Jones</p><br>
            <p>2006 - Chris Arnold, Josh Sefchick, Brian Wilson</p><br>
            <p>2005 - Chris Arnold, John Arnold, Gary Buchsen, Chris Straub</p><br>
            <p>2004 - Gary Buchsen, Jarrod Cipresse, Todd Feltz</p><br>
            <p>2003 - John Arnold, Bob Carozza, Drew Hurt, Roger Hurt</p><br>
            <br><br>
            <p><b>Most Scramble Titles (7)</b> - Chris Arnold, John Arnold, Ed Sabol, and Josh Sefchick</p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>