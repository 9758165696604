<template>
    <div>
        <h1 class="text-center">History</h1>
        <br>
        <p>In 2002, Joel Hurt and Stephanie Malisko were married at Hidden Valley Resort.  Prior to the wedding, Joel’s bachelor party was a golf weekend at Coudersport Golf Club with lodging provided at Camp Larsen hosted by best men Drew Hurt and Greg Dale.  The mix of attendees had immediate camaraderie and the fun was non-stop.  At the wedding, there was much discussion about perpetuating the bachelor party weekend as an annual event.</p>
        <br>
        <p>That put the wheels in motion to establish the Gray Jacket with Drew taking ownership to organize.  The venue was determined to be Hidden Valley, an award-winning golf course where the group had a history with the perfect central location.  The format was determined to be match play and the initial invites were sent out to a select crew of upstanding men.   Most importantly, Big Jim Sydeski was secured to act as unofficial host and, most importantly, executive chef.</p>
        <br>
        <p>This marks the twenty-first annual Gray Jacket. There is a waitlist to enter. Those who have participated will attest that this is one kick-ass event unmatched by any other golf tournament across the globe.</p>
        <br>
        <p>Notes:</p>
        <ul>
            <li>The actual Gray Jacket is a classic JC Penney original circa 1973 and originally purchased by two-time finalist Roger Hurt.  While the purchase price may have only been $29.99, any Gray Jacket champion will attest it is truly priceless.</li>
            <li>Other venues who bid to host were Coudersport Golf Club, Toftrees in State College, Cross Creek in Titusville and Pebble Beach before, ultimately, Hidden Valley was chosen.</li>
            <li>Of the five golfers who have played in every Gray Jacket, four attended Joel's bachelor party</li>
            <li>Of the sixteen golfers in this years field, eight attended Joel's wedding</li>
            <li>Prior to the 2014 Jacket, the Gray Jacket trophy was named the Big Jim Sydeski Trophy to recognize Big Jim for all his efforts in making the event such a success.</li>
        </ul>
    </div>
</template>

<script>

// TODO: Eighteenth, update automatically
// TODO: Stats update automatically

export default {

}
</script>

<style>

</style>