import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import History from "../views/History.vue"
import Format from "../views/Format.vue"
import Drinks from "../views/Drinks.vue"
import Programs from "../views/Programs.vue"
import Champions from "../views/Champions.vue"
import Scramble from "../views/Scramble.vue"
// import Cards from "../views/Cards.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: "/history",
    name: "History",
    component: History
  },
  {
    path: "/format",
    name: "Format",
    component: Format
  },
  {
    path: "/drinks",
    name: "Drinks",
    component: Drinks
  },
  {
    path: "/programs",
    name: "Programs",
    component: Programs
  },
  {
    path: "/champions",
    name: "Champions",
    component: Champions
  },
  {
    path: "/scramble",
    name: "Scramble",
    component: Scramble
  },
  /*
  {
    path: "/cards",
    name: "Cards",
    component: Cards
  }
  */
]

const router = new VueRouter({
  routes
})

export default router
