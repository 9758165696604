<template>
    <b-navbar id="nb" variant="secondary" type="dark">
        <b-navbar-brand href="/">
            <img class="logo" src="@/assets/img/logo.svg"/>
            Gray Jacket
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-item href="/#/history" right>History</b-nav-item>
                <b-nav-item href="/#/format" right>Format</b-nav-item>
                <b-nav-item href="/#/programs" right>Programs</b-nav-item>
                <b-nav-item-dropdown text="Traditions" right>
                    <b-dropdown-item href="/#/drinks" right>Drinks</b-dropdown-item>
                    <!-- <b-dropdown-item href="" right>Card Games</b-dropdown-item> -->
                </b-nav-item-dropdown>
                <b-nav-item-dropdown text="Data" right>
                    <b-dropdown-item href="/#/champions" right>Individual Champions</b-dropdown-item>
                    <b-dropdown-item href="/#/scramble" right>Scramble Champions</b-dropdown-item>
                    <b-dropdown-item href="http://db.gray-jacket.com/" right>Database</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
export default {
    name: "DesktopNavbar"
}
</script>

<style>

.logo {
    height: 60px;
    margin-right: 5px;
}

</style>