<template>
  <div class="home">
    <div class="home__jmbo">
        <img class="home__logo" src="@/assets/img/logo_big.svg" />
    </div>
    <p>Darwinism at its best and universally known as the toughest challenge in all of golf as participants play 72 grueling holes in less than 48 hours without the benefit of rest.  Some of the toughest competitors mix in a healthy dose of alcohol to enhance the test.  Who has what it takes to be wearing Gray on Sunday?</p>
    <countdown 
        :friday="fridayTime"
        :saturday="saturdayTime"
        :sunday="sundayTime"
    />
  </div>
</template>

<style lang="scss">

.home {
    &__jmbo {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    &__logo {
        width: 400px;
        max-width: 80%;
    }
}
</style>

<script>
import Countdown from '../components/Countdown.vue'

export default {
    name: 'Home',
    components: {
        "countdown": Countdown
    },
    beforeMount: function() {
        const fri = new Date(2024, 6, 12, 13, 0);
        const sat = new Date(2024, 6, 13, 8, 0);
        const sun = new Date(2024, 6, 14, 8, 0);
        this.fridayTime = fri.getTime()
        this.saturdayTime = sat.getTime()
        this.sundayTime = sun.getTime()
    },
    data: () => {return {
        fridayTime: 0,
        saturdayTime: 0,
        sundayTime: 0
    }}
}
</script>