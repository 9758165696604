<template>
    <div>
        <h1 class="text-center">Gray Jacket Programs</h1>
        <p>Perhaps there is no more respected tradition than the annual Gray Jacket program. Pick up a copy here (if you can afford the consistently increasing prices)!</p>
        <div class="programs__links">
            <div :key="yr" v-for="yr in program_years" class="programs__row">
                <b-button 
                    class="programs__link"
                    @click="redirect(`/programFiles/GJ_${yr}_Program.pdf`)"
                    :variant="(yr == program_years[0]) ? 'primary' : 'secondary'"
                >
                    {{yr}}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Programs",
    data: () => {return {
        program_years: [
            2024,
            2023,
            2022,
            2021,
            2020,
            2019,
            2018,
            2017,
            2016,
            2015,
            2014,
            2013,
            2012,
            2011,
            2010,
            2009,
            2008
        ]
    }},
    methods: {
        redirect: loc => {
            window.location.href = loc;
        }
    }
}
</script>

<style lang="scss">

.programs {
    &__row {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
    &__link {
        width: 100%;
        max-width: 300px;
    }
}

</style>