<template>
    <div id="app">
        <nav-desktop v-if="!mobile"/>
        <nav-mobile v-if="mobile" />
        <br>
        <router-view id="rtr"/>
    </div>
</template>

<script>
import NavDesktop from "./components/NavDesktop.vue"
import NavMobile from "./components/NavMobile.vue"

export default {
    name: "Gray Jacket",
    components: {
        "nav-desktop": NavDesktop,
        "nav-mobile": NavMobile
    },
    data: () => {return {
        mobile: (screen.width < 800)
    }}
}
</script>

<style lang="scss">
$app-width: 800px;
$lrdist: calc(calc(100vw - $app-width) / 2);

#rtr {
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

html {
    font-size: 17px;
}

#app {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media screen and (min-width: 800px) {
    #rtr {
        padding-left: $lrdist;
        padding-right: $lrdist;
    }

    #nb {
        padding-left: $lrdist;
        padding-right: $lrdist;
    }
}
</style>
