<template>
    <div id="countdown">
        <h4 class="text-center">Gray Jacket Countdown</h4>
        <div class="countdown">
            <div class="countdown__day">
                <h5>Seeding</h5>
                <div v-if="!fridayStarted" class="countdown__counter">
                    <p class="countdown__count">{{fridayLeft.days}} days</p>
                    <p class="countdown__count">{{fridayLeft.hours}} hours</p>
                    <p class="countdown__count">{{fridayLeft.minutes}} minutes</p>
                    <p class="countdown__count">{{fridayLeft.seconds}} seconds</p>
                </div>
                <div v-if="fridayStarted && !fridayEnded" class="countdown__running">
                    <p>Currently in play</p>
                </div>
                <div v-if="fridayEnded" class="countdown__finished">
                    <p>Complete!</p>
                </div>
            </div>
            <div class="countdown__day">
                <h5>Round 1/2</h5>
                <div v-if="!saturdayStarted" class="countdown__counter">
                    <p class="countdown__count">{{saturdayLeft.days}} days</p>
                    <p class="countdown__count">{{saturdayLeft.hours}} hours</p>
                    <p class="countdown__count">{{saturdayLeft.minutes}} minutes</p>
                    <p class="countdown__count">{{saturdayLeft.seconds}} seconds</p>
                </div>
                <div v-if="saturdayStarted && !saturdayEnded" class="countdown__running">
                    <p>Currently in play</p>
                </div>
                <div v-if="saturdayEnded" class="countdown__finished">
                    <p>Complete!</p>
                </div>
            </div>
            <div class="countdown__day">
                <h5>Round 3/4</h5>
                <div v-if="!sundayStarted" class="countdown__counter">
                    <p class="countdown__count">{{sundayLeft.days}} days</p>
                    <p class="countdown__count">{{sundayLeft.hours}} hours</p>
                    <p class="countdown__count">{{sundayLeft.minutes}} minutes</p>
                    <p class="countdown__count">{{sundayLeft.seconds}} seconds</p>
                </div>
                <div v-if="sundayStarted && !sundayEnded" class="countdown__running">
                    <p>Currently in play</p>
                </div>
                <div v-if="sundayEnded" class="countdown__finished">
                    <p>Complete!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["friday", "saturday", "sunday"],
    data: () => {return {
        currentTime: 0,
        fridayStarted: false,
        fridayEnded: false,
        saturdayStarted: false,
        saturdayEnded: false,
        sundayStarted: false,
        sundayEnded: false,
        fridayLeft: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        },
        saturdayLeft: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        },
        sundayLeft: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        }
    }},
    mounted: function() {
        // Get the current time
        this.currentTime = (new Date()).getTime();
        this.updateTime()
        // Add a window timeout to update the time
        setInterval(this.updateTime, 1000);
    },
    methods: {
        updateTime: function () {
            this.currentTime += 1000;
            // Calculate the time delta
            const fridayDelta = this.friday - this.currentTime;
            const saturdayDelta = this.saturday - this.currentTime;
            const sundayDelta = this.sunday - this.currentTime;
            // Calculate friday times
            if (fridayDelta < 0) {
                this.fridayStarted = true;
                if (fridayDelta < -(1000 * 60 * 60 * 6)) {
                    this.fridayEnded = true;
                }
            } else {
                this.fridayLeft = {
                    days: Math.floor(fridayDelta / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((fridayDelta / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((fridayDelta / (1000 * 60)) % 60),
                    seconds: Math.floor((fridayDelta / 1000) % 60)
                }
            }
            // Calculate saturday times
            if (saturdayDelta < 0) {
                this.saturdayStarted = true;
                if (saturdayDelta < -(1000 * 60 * 60 * 6)) {
                    this.saturdayEnded = true;
                }
            } else {
                this.saturdayLeft = {
                    days: Math.floor(saturdayDelta / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((saturdayDelta / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((saturdayDelta / (1000 * 60)) % 60),
                    seconds: Math.floor((saturdayDelta / 1000) % 60)
                }
            }
            // Sunday times
            if (sundayDelta < 0) {
                this.sundayStarted = true;
                if (sundayDelta < -(1000 * 60 * 60 * 6)) {
                    this.sundayEnded = true;
                }
            } else {
                this.sundayLeft = {
                    days: Math.floor(sundayDelta / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((sundayDelta / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((sundayDelta / (1000 * 60)) % 60),
                    seconds: Math.floor((sundayDelta / 1000) % 60)
                }
            }
        }
    }
}
</script>

<style lang="scss">

.countdown {
    display: flex;
    &__day {
        border: 1px solid black;
        flex: 1;
        text-align: center;
    }
    &__count {
        margin-bottom: 0px;
        color: blue;
    }
    &__running {
        color: orange;
        font-weight: 600;
        font-size: 1.5rem;
    }
    &__finished {
        color: green;
        font-weight: 600;
        font-size: 1.5rem;
    }

}

</style>