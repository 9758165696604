<template>
    <div>
        <h1 class="text-center">Format</h1>
        <br>
        <p>The Gray Jacket is a match-play event with handicap.  Handicaps are determined by the Gray Jacket Handicap Committee.</p>
        <h5 class="text-center">Friday Seeding Round</h5>
        <p>Friday is a medal-play round where players are seeded for match-play based on their net scores.  Triple-bogey is the maximum score in the Friday seeding round.  In the event that the Friday seeding round cannot be completed (weather, darkness, too many Gray Jackets, etc.), the Saturday pairings will be drawn randomly.</p>
        <h5 class="text-center">Match-Play</h5>
        <p>Each competitor plays four matches.  The matches are nine holes, net of handicap.  <b><i>In the case of a tie after nine holes, whichever golfer last won a hole wins the match.</i></b></p>
        <br>
        <p>The bracket is set-up for the winners and losers of each front nine match to play each other on the back nine.  The first two matches are played Saturday morning with the final two matches played on Sunday morning.  The Gray Jacket Champion will be the lone golfer who wins four matches without a loss.</p>
        <h5 class="text-center">Gray Jacket Scramble</h5>
        <p>The Gray Jacket Scramble is a four-man scramble held on Saturday afternoon.  The teams are determined based on the golfers’ gross Friday scores via the S-curve (e.g. 1-8-9-16 golfers would be one team, 2-7-10-15 would be another, etc.).</p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>